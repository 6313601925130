import React from "react";

import styles from "./styles.scss";

export const BASE_LOGO_PATH = "https://static.rotoql.com";

export interface TeamLogoProps {
  leagueName: string;
  teamAbbreviation: string;
  size?: number;
  style?: Object;
  containerStyle?: Object;
  className?: string;
  containerClassName?: string;
}

const LEAGUE_NAME_OVERRIDES: any = {
  CBK: "NCAA",
  CFB: "NCAA",
};

/**
 * Component to display team logo
 *
 * @param leagueName NFL, NBA, NCAA
 * @param teamAbbreviation preferredAbbreviation
 * @param size width/height in pixels
 * @param style style object for the img tag
 * @param containerStyle style object for the container div
 * @param className class string for the img tag
 * @param containerClassName class string for the container div
 */
const TeamLogo = ({
  leagueName,
  teamAbbreviation,
  size = 24,
  style,
  containerStyle,
  className = "",
  containerClassName = "",
}: TeamLogoProps) => {
  const upperCaseLeagueName: string = leagueName.toUpperCase();
  const leagueNameOverride: string = LEAGUE_NAME_OVERRIDES[upperCaseLeagueName]
    ? LEAGUE_NAME_OVERRIDES[upperCaseLeagueName]
    : upperCaseLeagueName;
  let imgPath = `${BASE_LOGO_PATH}/team/logo/${leagueNameOverride}/${teamAbbreviation?.toUpperCase()}`;
  let altTxt = `${teamAbbreviation} Team Logo`;
  let sportImgClass = "";
  if (upperCaseLeagueName === "ATP" || upperCaseLeagueName === "WTA") {
    imgPath = `${BASE_LOGO_PATH}/flags/medium/${teamAbbreviation?.replace(/ /g, "_") ?? "noflag"}`;
    altTxt = `${teamAbbreviation} Country Flag`;
    sportImgClass = "rotoql-team-logo__image--tennis";
  }
  return (
    <div
      style={{
        ...containerStyle,
        maxWidth: `${size}px`,
        minWidth: `${size}px`,
        width: `${size}px`,
        height: `${size}px`,
      }}
      className={containerClassName}
    >
      {teamAbbreviation !== "--" ? (
        <picture>
          <source data-srcset={`${imgPath}.webp`} type="image/webp" />
          <source data-srcset={`${imgPath}.png`} type="image/png" />
          <img
            style={style}
            className={`rotoql-team-logo__image ${sportImgClass} ${className} lazyload`}
            data-src={`${imgPath}.png`}
            aria-label={altTxt}
            alt=""
          />
        </picture>
      ) : null}
      <style jsx global>
        {styles}
      </style>
    </div>
  );
};

export default TeamLogo;
