import React, { useMemo } from "react";
import moment from "moment-timezone";
import { DEFAULT_BOOK_ID } from "@rotoql/common-services";
import { DEFAULT_SPORT, DEFAULT_TIMEZONE, WebSportItem } from "../../constants";
import { LIVE_EVENTS_QUERY } from "../../gql/queries";
import { useQuery } from "@apollo/client";
import { sortByDate } from "../../services/DateService";
import { rfc3986EncodeURIComponent } from "../../services/UtilService";
import styles from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Router from "next/router";
import AnalyticsService from "src/services/AnalyticsService";
import { COMP_RED } from "src/colors";
import TeamLogo from "src/components/Team/TeamLogo";
import { useCarousel } from "../Carousel";
import FocusedTextLink from "../FocusedTextLink";
import { getGameOddsText, useGameProgressAndChannel } from "src/services/EventService";

const boldText = {
  fontWeight: "bold",
};
const redText = {
  color: COMP_RED,
};

export interface LiveGameBarProps {
  sport?: WebSportItem;
  ssr?: boolean;
}

/**
 * This is the live list of games playing today and their scores/times/betting info.
 */
function LiveGameBar({ sport = DEFAULT_SPORT }: LiveGameBarProps) {
  const before = useMemo(() => {
    // Determine the before date for the events query
    const date = moment().tz(DEFAULT_TIMEZONE).endOf("day").utc();
    if (sport.hasConfiguredSchedule) {
      if (sport.key === "NFL") {
        return date.add(12, "days");
      }
      return date.add(6, "days");
    }
    return date;
  }, [sport]);
  const liveEventsResult = useQuery(LIVE_EVENTS_QUERY, {
    ssr: false,
    // context: { debatch: true }, // TODO this gets stuck loading
    variables: {
      league: sport.key,
      before, // week ahead for weekly sports
      after: moment().tz(DEFAULT_TIMEZONE).startOf("day").utc(),
      books: [DEFAULT_BOOK_ID],
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  let games = liveEventsResult?.data?.events ?? [];
  const loading = liveEventsResult?.loading;

  // Same as mobile. Move past games to end of the list.
  games = games.slice(0).sort((eventA: any, eventB: any) => {
    if (eventA.eventState === "FINAL" && eventB.eventState !== "FINAL") {
      return 1;
    }
    if (eventA.eventState !== "FINAL" && eventB.eventState === "FINAL") {
      return -1;
    }
    return sortByDate(eventA, eventB);
  });

  const { Carousel, paginate, getPageInfo } = useCarousel(
    loading
      ? [1, 2, 3, 4, 5].map((i) => <LiveGamePlaceholder key={i} last={i === 5} />)
      : games.map((game: any, i: number) => (
          <LiveGameCard game={game} sport={sport} last={i === games.length - 1} key={i} />
        )),
    {
      contentHash: String(loading) + games.map((x: any) => x.id).join(""),
    }
  );

  const [page, totalPages] = getPageInfo.current();
  const isFirstPage = page <= 1;
  const isLastPage = page >= totalPages;

  const hasLiveGames = games.some((g: any) => g.eventState === "STARTED");

  return (
    <div className="live-game-bar" style={loading || games.length ? {} : { padding: 0 }}>
      {hasLiveGames ? <LiveIcon /> : null}
      <div className="HorizontalScrollDiv">
        <Carousel />
      </div>
      <FocusedTextLink
        aria-label="LiveGameBar Scroll Left"
        className={`HorizontalScrollArrowLeft ${hasLiveGames ? "live" : ""}`}
        onClick={() => paginate.current(false)}
        style={{ display: isFirstPage ? "none" : "flex" }}
      >
        <FontAwesomeIcon icon={faChevronLeft} color="#ffffff" style={{ fontSize: 20 }} />
      </FocusedTextLink>
      <FocusedTextLink
        aria-label="LiveGameBar Scroll Right"
        className="HorizontalScrollArrowRight"
        onClick={() => paginate.current(true)}
        style={{ display: isLastPage ? "none" : "flex" }}
      >
        <FontAwesomeIcon icon={faChevronRight} color="#ffffff" style={{ fontSize: 20 }} />
      </FocusedTextLink>
      <style jsx>{styles}</style>
    </div>
  );
}

const LiveGameCard = ({ game, sport, last }: { game: any; sport: WebSportItem; last: boolean }) => {
  // Styles
  const homeStyle = game.homeScore > game.awayScore ? boldText : {};
  const awayStyle = game.awayScore > game.homeScore ? boldText : {};
  const awayRank = game?.awayTeamSeed ?? game?.awayTeam?.teamStats?.rank;
  const homeRank = game?.homeTeamSeed ?? game?.homeTeam?.teamStats?.rank;
  const hasRank = awayRank || homeRank;
  let scheduleStyle: any = {};
  if (game.eventState === "FINAL") {
    scheduleStyle = { ...scheduleStyle, ...boldText };
  }
  if (game.eventState === "POSTPONED") {
    scheduleStyle = { ...scheduleStyle, ...redText };
  }

  // Link info
  const slugId = rfc3986EncodeURIComponent(game.slugId);

  const onClick = () => {
    AnalyticsService.track("Game Ticker Click", {
      sport: sport.name,
      slugId: game.slugId,
    });
    Router.push(`/game?sport=${sport.url}&slug=${slugId}`, `/${sport.url}/game-predictions/${slugId}`);
  };

  const gameTimeArr = useGameProgressAndChannel(game).match(/.{1,22}(\s|$)/g);

  return (
    <FocusedTextLink onClick={onClick}>
      <span className={`LiveGameCard ${last ? "LiveGameCard--last" : ""}`}>
        <div className="LiveGameCardRow" style={{ justifyContent: "space-between" }}>
          <div style={awayStyle}>
            {hasRank ? <div className="ApRank">{awayRank || ""}</div> : null}
            {getTeamName(game.awayTeam, sport)}
          </div>
          <div style={homeStyle}>
            {hasRank ? <div className="ApRank">{homeRank || ""}</div> : null}
            {getTeamName(game.homeTeam, sport)}
          </div>
        </div>
        <div className="LiveGameCardRow" style={{ justifyContent: "space-between" }}>
          <TeamLogo
            containerClassName="TeamLogoContainer"
            teamAbbreviation={game.awayTeam[sport.isTennis ? "country" : "preferredAbbreviation"]}
            leagueName={sport.key}
            size={32}
          />
          <div style={awayStyle}>{game.awayScore ?? "-"}</div>
          <div className="GameTime" style={scheduleStyle}>
            <span>{gameTimeArr?.[0]}</span>
            {Boolean(gameTimeArr?.[1]) && <span>{gameTimeArr?.[1]}</span>}
          </div>
          <div style={homeStyle}>{game.homeScore ?? "-"}</div>
          <TeamLogo
            containerClassName="TeamLogoContainer"
            teamAbbreviation={game.homeTeam[sport.isTennis ? "country" : "preferredAbbreviation"]}
            leagueName={sport.key}
            size={32}
          />
        </div>
        <div className="LiveGameCardRow" style={{ justifyContent: "center" }}>
          <div className="BetInfo">{getGameOddsText(game)}</div>
        </div>
      </span>
    </FocusedTextLink>
  );
};

const LiveGamePlaceholder = ({ last }: { last: boolean }) => {
  return (
    <span className={`LiveGameCard loading ${last ? "LiveGameCard--last" : ""}`}>
      <div className="LiveGameCardRow" style={{ justifyContent: "space-between" }}>
        <div className="placeholder" style={{ width: 90 }}>
          &nbsp;
        </div>
        <div className="placeholder" style={{ width: 90 }}>
          &nbsp;
        </div>
      </div>
      <div className="LiveGameCardRow" style={{ justifyContent: "space-between", marginTop: 6 }}>
        <div className="TeamLogoContainer placeholder" style={{ width: 26, height: 26 }} />
        <div style={{ width: 8 }}>&nbsp;</div>
        <div className="GameTime placeholder" style={{ height: 24 }}>
          &nbsp;
        </div>
        <div style={{ width: 8 }}>&nbsp;</div>
        <div className="TeamLogoContainer placeholder" style={{ width: 26, height: 26 }} />
      </div>
      <div className="LiveGameCardRow" style={{ justifyContent: "center", marginTop: 6 }}>
        <div className="BetInfo placeholder" style={{ width: 50, height: 12 }} />
      </div>
    </span>
  );
};

/**
 * Helpers
 */

/**
 * Get the team named based on which sport it's for
 */
const getTeamName = (team: any, sport: WebSportItem) => {
  return sport.isCollege ? team.fullName : team.lastName;
};

/**
 * "Live" icon
 */

const LiveIcon = () => (
  <div className="LiveIconDiv">
    LIVE
    <div className="LiveIconCircle">
      <div />
    </div>
  </div>
);

export default LiveGameBar;
