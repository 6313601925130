import React, { useRef, useMemo, useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import _ from "lodash";
import useResizeObserver from "use-resize-observer";
import FocusedTextLink from "./FocusedTextLink";

// NOTE Browser Compatibility: Chrome ok. Firefox ok but scrolls past end and sticks there sometimes after flick scroll.
// Safari not great. Basically (1) no scroll animation on button press and (2) scroll snaps past end of container.
// For (2), one possible tradeoff is to only allow full pages of items. Pagination would then be inaccurate on last page.

// NOTE Fade Mask: Make sure the width of the container is at least 1 item + 20px wide or gradient will hide part of it.

export interface CarouselOptions {
  onResize?: any;
  contentHash: any; // This component needs to render efficiently to allow scrolling, so we provide this to detect change
  analyticTracking?: () => void;
}

export function useCarousel(items: any[], { contentHash, onResize = () => {}, analyticTracking }: CarouselOptions) {
  // eslint-disable-next-line no-undef
  const scrollContainer = useRef<HTMLDivElement>(null);
  const lastPaginated = useRef<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);

  // Offset is how many items we've scrolled
  const [_offset, setOffset] = useState(0);

  // Reset state on items or itemsPerPage change
  useEffect(() => {
    setOffset(0);
  }, [contentHash, itemsPerPage]);

  // Get current offest from offsetPx
  const findOffsetByOffsetPx = (offsetPx: number) => {
    const _offsetPx = offsetPx + 3; // +1px for bugfix on left edge. safari needs +3
    const children = [
      // eslint-disable-next-line no-undef
      ...((scrollContainer.current?.children ?? []) as HTMLElement[]),
    ];
    const indexReversed = children.reverse().findIndex((c) => c.offsetLeft < _offsetPx);
    const index = children.length - 1 - indexReversed;
    return index;
  };

  // Get current page and totalPages from _offset and itemsPerPage
  const getPageInfo = useRef<any>();
  getPageInfo.current = () => {
    const page = Math.floor(_offset / itemsPerPage) + 1;
    const totalPages = Math.ceil(items.length / itemsPerPage);
    return [page, totalPages];
  };

  // Paginate. ref prevents stale state
  const paginate = useRef<any>();
  paginate.current = (forward: boolean = true) => {
    const nextOffset = _offset + (forward ? 1 : -1) * itemsPerPage;
    const isNavigationValid = nextOffset >= 0 && nextOffset < items.length;
    if (!isNavigationValid) {
      return;
    }
    const offsetPx =
      // eslint-disable-next-line no-undef
      (scrollContainer.current?.children[nextOffset] as HTMLElement | null)?.offsetLeft ?? 0;
    lastPaginated.current = Date.now();
    scrollContainer.current?.scrollTo({ left: offsetPx, behavior: "smooth" });
    setOffset(nextOffset);
  };

  // Sync page to current scroll position. ref prevents stale state
  const syncOffset = useRef<any>();
  syncOffset.current = () => {
    if (lastPaginated.current + 1000 > Date.now()) {
      return;
    } // Ignore sync if paginated in last second
    const nextOffset = findOffsetByOffsetPx(scrollContainer.current?.scrollLeft ?? 0);
    const offsetFirstItemLastPage = items.length - Math.max(1, items.length % itemsPerPage);
    if (nextOffset > offsetFirstItemLastPage) {
      return;
    } // Safari allows scrolling beyond snap
    setOffset(nextOffset);
  };

  // Pagination
  function CarouselNav() {
    const [page, totalPages] = getPageInfo.current();

    const isFirstPage = page <= 1;
    const isLastPage = page >= totalPages;

    if (!items.length) {
      return null;
    }

    return (
      <div className="carousel-nav">
        <FocusedTextLink
          aria-label="Previous Slide Button"
          onClick={() => {
            paginate.current(false);
            analyticTracking?.();
          }}
          className="nav-button"
          style={{
            opacity: isFirstPage ? 0.4 : 1,
            backgroundColor: "#dfdfdf",
            borderRadius: 4,
          }}
        >
          <Icon color="#666" path={mdiChevronLeft} size="25px" />
        </FocusedTextLink>
        <div className="nav-page">
          {page} / {totalPages}
        </div>
        <FocusedTextLink
          aria-label="Next Slide Button"
          onClick={() => {
            paginate.current(true);
            analyticTracking?.();
          }}
          className="nav-button"
          style={{
            opacity: isLastPage ? 0.4 : 1,
            backgroundColor: "#dfdfdf",
            borderRadius: 4,
          }}
        >
          <Icon color="#666" path={mdiChevronRight} size="25px" />
        </FocusedTextLink>
        <style jsx>{`
          .carousel-nav {
            display: flex;
            align-items: center;
          }
          .nav-page {
            font-weight: bold;
            margin: 0 6px;
            font-size: 13px;
            color: #999;
            white-space: nowrap;
          }
        `}</style>
      </div>
    );
  }

  // Abstract carousel.
  // NOTE: useMemo prevents immediate render due to _offset which would skip animation.
  // It appears that useMemo doesn't prevent the render altogether oddly.
  const Carousel = useMemo(
    () =>
      function AbstactCarousel() {
        const handleScroll = _.throttle(() => syncOffset.current(), 250);
        const [page, totalPages] = getPageInfo.current();

        // Auto set items per page + onResize callback
        useResizeObserver({
          ref: scrollContainer,
          onResize: () => {
            const width = scrollContainer.current?.clientWidth;
            const height = scrollContainer.current?.clientHeight;
            onResize({ width, height });
            // NOTE findOffsetByOffsetPx finds the index. we +1 to switch to a 1-indexed list and
            // -1 to get the previous item. -20 accounts for fade mask. 1 is the fallback.
            const lastFullyVisibleItem = findOffsetByOffsetPx(width ?? 0 - 20) || 1;
            setItemsPerPage(lastFullyVisibleItem);
          },
        });

        return (
          <div ref={scrollContainer} className="carousel-track" onScroll={handleScroll}>
            {items.map((item: any, i: number) => (
              <div key={i} className="carousel-pane">
                {item}
              </div>
            ))}
            {/* Extra space for non-full page */}
            <div style={{ minWidth: "100%" }} />
            <style jsx>{`
              .carousel-track {
                --fade-opacity: ${page === totalPages ? 1 : 0};
                position: relative;
                display: flex;
                overflow-x: auto;
                scroll-snap-type: x mandatory;
                scroll-snap-stop: always;
                scrollbar-width: none;
                mask-image: linear-gradient(270deg, rgba(0, 0, 0, var(--fade-opacity)) 0, rgba(0, 0, 0, 1) 20px);
              }
              .carousel-track::-webkit-scrollbar {
                display: none;
              }
              .carousel-pane:nth-of-type(${itemsPerPage}n + 1) {
                scroll-snap-align: start;
              }
              .carousel-pane:not(:last-child) {
                margin-right: 15px;
              }
            `}</style>
          </div>
        );
      },
    [contentHash, itemsPerPage]
  );

  return { Carousel, CarouselNav, paginate, getPageInfo };
}
